import Icon from "../../../../components/Icon/Icon";
import i18n from "../../../../library/i18next";
import useCandidateScoreResultVm from "../Candidate-Score-ResultVM";
import { NSCScoreResultDetailsStyle } from "./ScoreResultDetails.style";

const data = [
  {
    candidate: "Batuhan",
    items: [
      {
        isAiScored: true,
        reason:
          "Porta duis ex ad facilisis venenatis proin rhoncus ridiculus. Platea dignissim dignissim eu feugiat ornare fermentum placerat. Accumsan felis aliquam euismod eget consequat tempus. Massa a ipsum ac euismod primis facilisi. Eget nullam dictumst eu ex dictum varius nam ex potenti. Ante risus est facilisi sapien bibendum vivamus, mattis maximus sodales. Risus condimentum tellus hac facilisis dolor aptent quam sem.",
        reviewMetric: "Pozisyona Uyumu",
      },
    ],
  },
  {
    candidate: "Mert",
    items: [
      {
        isAiScored: false,
        reason:
          "Porta duis ex ad facilisis venenatis proin rhoncus ridiculus. Platea dignissim dignissim eu feugiat ornare fermentum placerat. Accumsan felis aliquam euismod eget consequat tempus. Massa a ipsum ac euismod primis facilisi. Eget nullam dictumst eu ex dictum varius nam ex potenti. Ante risus est facilisi sapien bibendum vivamus, mattis maximus sodales. Risus condimentum tellus hac facilisis dolor aptent quam sem.",
        reviewMetric: "Pozisyona Uyumu",
      },
    ],
  },
];

const ScoreResultDetails = () => {
  const { scoreResultDetailData } = useCandidateScoreResultVm();
  return (
    <NSCScoreResultDetailsStyle.Container>
      {scoreResultDetailData.data.map((i) => (
        <NSCScoreResultDetailsStyle.ScoreResultDetail
          key={i.candidate}
          isBackgroundActive={data.length <= 1}
        >
          {data.length > 1 && (
            <NSCScoreResultDetailsStyle.ScoreResultDetailItem>
              {i.candidate}
            </NSCScoreResultDetailsStyle.ScoreResultDetailItem>
          )}
          {i.items.map((item) => (
            <NSCScoreResultDetailsStyle.ScoreResultDetailItem
              key={item.reviewMetric}
            >
              <NSCScoreResultDetailsStyle.ScoreResultDetailReviewMetric>
                {item.isAiScored && <Icon name="AI" />} {item.reviewMetric}
              </NSCScoreResultDetailsStyle.ScoreResultDetailReviewMetric>
              <NSCScoreResultDetailsStyle.ScoreResultDetailReason>
                <NSCScoreResultDetailsStyle.ScoreResultDetailReasonText>
                  {item.reason
                    ? item.reason
                    : i18n.t("candidate.noDescription")}
                </NSCScoreResultDetailsStyle.ScoreResultDetailReasonText>
              </NSCScoreResultDetailsStyle.ScoreResultDetailReason>
            </NSCScoreResultDetailsStyle.ScoreResultDetailItem>
          ))}
        </NSCScoreResultDetailsStyle.ScoreResultDetail>
      ))}
    </NSCScoreResultDetailsStyle.Container>
  );
};

export default ScoreResultDetails;
