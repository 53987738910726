import styled from "styled-components";

export namespace NSCScoreResultDetailsStyle {
  export const Container = styled.div`
    display: flex;
    gap: 24px;
  `;

  export const ScoreResultDetailItem = styled.div`
  display: flex;
  flex-direction column;
  gap: 8px;
  background: ${(props) => props.theme.colors.primary.tint};
  border-radius: 16px;
  padding: 16px;
  `;

  export const ScoreResultDetail = styled.div<{
    isBackgroundActive?: boolean;
  }>`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${ScoreResultDetailItem} {
      ${(props) =>
        props.isBackgroundActive &&
        `background: ${props.theme.colors.primary.tint05} `}
    }
  `;

  export const ScoreResultDetailReviewMetric = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const ScoreResultDetailReason = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const ScoreResultDetailReasonText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;
}
