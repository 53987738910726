import styled, { css } from "styled-components";

import { NSContextMenuHOCStyle } from "../ContextMenuHOC/ContextMenuHOC.style";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";
import { NSHistoryTooltipStyle } from "../HistoryTooltip/HistoryTooltip.style";

export namespace NSTableListStyle {
  export const Base = styled.div`
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    box-sizing: border-box;
    padding: 16px 16px 40px 16px;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.light.light};

    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.neutrals.grey03};
      border-radius: 20px;
    }
  `;
  export const Container = styled.table`
    border-collapse: collapse;

    border-spacing: 32px 0;
    width: 100%;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: 21px;
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const Header = styled.thead`
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.neutrals.grey01};
}
  `;

  export const HeaderLine = styled.tr`
    width: 100%;
    border-radius: 8px;
  `;

  export const HeaderColumn = styled.th`
    position: relative;
    min-width: 152px;
    width: max-content;
    text-align: left;
    padding: 12px 0 12px 8px;

    color: ${(props) => props.theme.colors.neutrals.grey05};

    :first-child {
      min-width: 24px;
      padding-left: 16px;
      padding-bottom: 8px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    :last-child {
      padding-right: 16px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  `;

  export const TransactionsText = styled.div`
    visibility: hidden;

    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.primary.primary};
  `;

  export const Line = styled.tr<{ checked?: boolean }>`
    width: 100%;
    border-radius: 8px;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.shadesOfdark.dark06};
    ${(props) =>
      props.checked &&
      css`
        background-color: ${props.theme.colors.neutrals.grey02};
        border-bottom: 0px;
      `}

    :hover {
      ${TransactionsText} {
        visibility: visible;
      }

      ${NSMainButtonStyle.ButtonBase} {
        svg {
          path {
            stroke: ${(props) => props.theme.colors.primary.primary};
          }
        }
      }

      outline: 2px solid blue;
    }
  `;

  export const Column = styled.td<{ isInTheFirstColumn?: boolean }>`
    padding: 0 8px;
    height: 56px;
    vertical-align: middle;

    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};

    ${(props) =>
      props.isInTheFirstColumn &&
      css`
        font-weight: ${props.theme.typography.fontWeights.demiBold};
      `}

    :first-child {
      padding-left: 16px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    :last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    ${NSContextMenuHOCStyle.Container} {
      width: auto;
    }
  `;

  export const ContextMenuColumn = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;

    ${NSContextMenuHOCStyle.Container} {
      justify-content: flex-end;
      ${NSMainButtonStyle.ButtonBase} {
        padding: 4px;
        border-radius: 8px;
        background-color: ${(props) => props.theme.colors.neutrals.grey02};
      }
    }
  `;

  export const TableListTbody = styled.tbody``;

  export const SpaceElement = styled.tr`
    height: 2px;
  `;

  export const SuspiciousEvent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: ${(props) => props.theme.colors.error.red01};
      }
    }

    ${NSHistoryTooltipStyle.HistoryTooltipBase} {
      .react-tooltip {
        max-width: 300px;
      }
    }

    ${NSHistoryTooltipStyle.HistoryTooltipLabel} {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 0;
      padding: 0;
    }
  `;

  export const SuspiciousEventLabel = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    margin-bottom: 8px;
  `;

  export const SuspiciousEventTitle = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: 12px;
  `;

  export const SuspiciousEventWarningText = styled.div``;

  export const SuspiciousEventQuestions = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    gap: 4px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
  `;
}
