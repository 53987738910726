import styled from "styled-components";

export namespace NSScoreDistributionStyle {
  export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
  `;

  export const Header = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    text-align: left;
    width: 100%;
  `;

  export const List = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 12px;
    gap: 8px;
  `;

  export const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  export const Score = styled.div`
    display: flex;
    gap: 4px;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const ScoreContainer = styled.div<{
    isBackgroundActive?: boolean;
    color?: string;
  }>`
    padding: 2px 8px;
    border-radius: 32px;

    ${(props) =>
      props.isBackgroundActive &&
      `:first-child {
        color: ${props.theme.colors.primary.tint05};
        background-color: ${props.theme.colors.primary.tint};
      }
      color: ${props.theme.colors.orange.orange};
      background-color: ${props.theme.colors.orange.orange02};`};
  `;

  export const ReviewMetric = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const Bold = styled.span`
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;
}
