import { ReactComponent as Add } from "./Add.svg";
import { ReactComponent as AddCircleOutlined } from "./AddCircleOutlined.svg";
import { ReactComponent as AllowCamera } from "./AllowCamera.svg";
import { ReactComponent as AllowMicrophone } from "./AllowMicrophone.svg";
import { ReactComponent as ArrowDown } from "./ArrowDown.svg";
import { ReactComponent as ArrowDownLeft } from "./ArrowDownLeft.svg";
import { ReactComponent as ArrowDownRight } from "./ArrowDownRight.svg";
import { ReactComponent as ArrowLeft } from "./ArrowLeft.svg";
import { ReactComponent as ArrowLeftRight } from "./ArrowLeftRight.svg";
import { ReactComponent as ArrowRestart } from "./ArrowRestart.svg";
import { ReactComponent as ArrowUp } from "./ArrowUp.svg";
import { ReactComponent as BatchAction } from "./BatchAction.svg";
import { ReactComponent as Calendar } from "./Calendar.svg";
import { ReactComponent as CalendarCheck } from "./CalendarCheck.svg";
import { ReactComponent as CalendarEmpty } from "./CalendarEmpty.svg";
import { ReactComponent as Check } from "./Check.svg";
import { ReactComponent as CheckWhite } from "./CheckWhite.svg";
import { ReactComponent as CheckBoxDelete } from "./CheckBoxDelete.svg";
import { ReactComponent as Chevron } from "./Chevron.svg";
import { ReactComponent as ChevronDown } from "./ChevronDown.svg";
import { ReactComponent as ChevronRight } from "./ChevronRight.svg";
import { ReactComponent as ChevronUp } from "./ChevronUp.svg";
import { ReactComponent as Close } from "./Close.svg";
import { ReactComponent as CloseCircleFilled } from "./CloseCircleFilled.svg";
import { ReactComponent as CloseShort } from "./CloseShort.svg";
import { ReactComponent as CommunicationDislike } from "./CommunicationDislike.svg";
import { ReactComponent as Copy } from "./Copy.svg";
import { ReactComponent as DownLeft } from "./DownLeft.svg";
import { ReactComponent as Download } from "./Download.svg";
import { ReactComponent as Draggable } from "./Draggable.svg";
import { ReactComponent as Edit } from "./Edit.svg";
import { ReactComponent as EditCandidateInfo } from "./EditCandidateInfo.svg";
import { ReactComponent as Folders } from "./Folders.svg";
import { ReactComponent as File } from "./File.svg";
import { ReactComponent as Help } from "./Help.svg";
import { ReactComponent as KebabVertical } from "./KebabVertical.svg";
import { ReactComponent as KeyboardIcon } from "./KeyboardIcon.svg";
import { ReactComponent as Layer } from "./Layer.svg";
import { ReactComponent as Left } from "./ChevronLeft.svg";
import { ReactComponent as Logo } from "./Logo.svg";
import { ReactComponent as LogoText } from "./LogoText.svg";
import { ReactComponent as Logout } from "./Logout.svg";
import { ReactComponent as News } from "./News.svg";
import { ReactComponent as Notification } from "./Notification.svg";
import { ReactComponent as Pause } from "./Pause.svg";
import { ReactComponent as Play } from "./Play.svg";
import { ReactComponent as Project } from "./Project.svg";
import { ReactComponent as RadioCircleFilled } from "./RadioCircleFilled.svg";
import { ReactComponent as Right } from "./ChevronRight.svg";
import { ReactComponent as Puzzle } from "./Puzzle.svg";
import { ReactComponent as ScoreChartDots } from "./ScoreChartDots.svg";
import { ReactComponent as ScreenExpand } from "./ScreenExpand.svg";
import { ReactComponent as Search } from "./Search.svg";
import { ReactComponent as Share } from "./Share.svg";
import { ReactComponent as ThankYouInterview } from "./ThankYouInterview.svg";
import { ReactComponent as TimeExpired } from "./TimeExpired.svg";
import { ReactComponent as Trash } from "./Trash.svg";
import { ReactComponent as TriangleWarning } from "./TriangleWarning.svg";
import { ReactComponent as Upload } from "./Upload.svg";
import { ReactComponent as UserAdd } from "./UserAdd.svg";
import { ReactComponent as HCLogo } from "./HCLogo.svg";
import { ReactComponent as Users } from "./Users.svg";
import { ReactComponent as VideoOff } from "./VideoOff.svg";
import { ReactComponent as Star } from "./Star.svg";
import { ReactComponent as Video } from "./Video.svg";
import { ReactComponent as Note } from "./Note.svg";
import { ReactComponent as Shuffle } from "./Shuffle.svg";
import { ReactComponent as Link } from "./Link.svg";
import { ReactComponent as GreenEllipse } from "./GreenEllipse.svg";
import { ReactComponent as PurpleEllipse } from "./PurpleEllipse.svg";
import { ReactComponent as Global } from "./Global.svg";
import { ReactComponent as BlueEllipse } from "./BlueEllipse.svg";
import { ReactComponent as CheckCircleFilled } from "./CheckCircleFilled.svg";
import { ReactComponent as WarningCircleInfo } from "./WarningCircleInfo.svg";
import { ReactComponent as WarningCircle } from "./WarningCircle.svg";
import { ReactComponent as CloseCircleOutlined } from "./CloseCircleOutlined.svg";
import { ReactComponent as AI } from "./AI.svg";
import { ReactComponent as AppWorking } from "./AppWorking.svg";
import { ReactComponent as LifePreserver } from "./LifePreserver.svg";
import { ReactComponent as InterviewCam } from "./InterviewCam.svg";
import { ReactComponent as Siri } from "./Siri.svg";
import { ReactComponent as SolarGreenEnergy } from "./SolarGreenEnergy.svg";
import { ReactComponent as TimeSchedule } from "./TimeSchedule.svg";
import { ReactComponent as WorkPresentationTime } from "./WorkPresentationTime.svg";
import { ReactComponent as DownloadSecondary } from "./DownloadSecondary.svg";
import { ReactComponent as SendShortlist } from "./SendShortlist.svg";
import { ReactComponent as SendLonglist } from "./SendLonglist.svg";
import { ReactComponent as SendAppliedlist } from "./SendAppliedlist.svg";
import { ReactComponent as Rescore } from "./Rescore.svg";
import { ReactComponent as StartUpPlan } from "./StartUpPlan.svg";
import { ReactComponent as Settings } from "./Settings.svg";
import { ReactComponent as Key } from "./Key.svg";
import { ReactComponent as Forward } from "./Forward.svg";
import { ReactComponent as Rewind } from "./Rewind.svg";
import { ReactComponent as ArrowRight } from "./ArrowRight.svg";
import { ReactComponent as Clock } from "./Clock.svg";
import { ReactComponent as InterviewDetail } from "./InterviewDetail.svg";
import { ReactComponent as TestDetail } from "./TestDetail.svg";
import { ReactComponent as BarChartIcon } from "./BarChartIcon.svg";
import { ReactComponent as Radar } from "./Radar.svg";
import { ReactComponent as Layers } from "./Layers.svg";
import { ReactComponent as Slips } from "./Slips.svg";

const icons = {
  Slips,
  CommunicationDislike,
  Radar,
  BarChartIcon,
  VideoOff,
  Calendar,
  TriangleWarning,
  Forward,
  Rewind,
  Key,
  ArrowRight,
  BatchAction,
  Clock,
  ArrowRestart,
  File,
  StartUpPlan,
  Play,
  TestDetail,
  ScoreChartDots,
  Close,
  Check,
  EditCandidateInfo,
  CloseShort,
  HCLogo,
  Notification,
  BlueEllipse,
  Draggable,
  Settings,
  Add,
  Right,
  Search,
  CloseCircleFilled,
  Puzzle,
  KeyboardIcon,
  AddCircleOutlined,
  KebabVertical,
  ChevronDown,
  ChevronUp,
  Folders,
  CheckWhite,
  ArrowDownRight,
  Rescore,
  ArrowDownLeft,
  ChevronRight,
  Logo,
  Edit,
  LogoText,
  CalendarEmpty,
  SendShortlist,
  SendLonglist,
  CalendarCheck,
  SendAppliedlist,
  Chevron,
  InterviewCam,
  Left,
  Users,
  Project,
  News,
  Help,
  Logout,
  Layer,
  Layers,
  Download,
  Copy,
  Trash,
  ArrowDown,
  DownLeft,
  ArrowUp,
  ScreenExpand,
  UserAdd,
  AllowCamera,
  AllowMicrophone,
  TimeExpired,
  ThankYouInterview,
  Pause,
  ArrowLeft,
  RadioCircleFilled,
  Upload,
  ArrowLeftRight,
  Share,
  Star,
  Video,
  Shuffle,
  Link,
  GreenEllipse,
  PurpleEllipse,
  Global,
  CheckCircleFilled,
  WarningCircleInfo,
  WarningCircle,
  AI,
  Note,
  CloseCircleOutlined,
  DownloadSecondary,
  AppWorking,
  LifePreserver,
  Siri,
  SolarGreenEnergy,
  TimeSchedule,
  WorkPresentationTime,
  InterviewDetail,
  CheckBoxDelete,
};

export default icons;
