import { useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "../../../../components/Icon/Icon";
import { NSTableListType } from "../../../../components/TableList/TableList.type";
import {
  GetSignedUrlDocument,
  GetVideoInterviewScoresByProjectIdDocument,
  SearchDocument,
  Video_Interview_Type_Enum,
} from "../../../../gql/graphql";
import {
  useGraphQLMutation,
  useGraphQLQuery,
} from "../../../../hooks/useGraphQL";
import HeatmapBadge from "./Badge/HeatmapBadge";
import { NSHeatmapStyle } from "./Heatmap.style";
import i18n from "../../../../library/i18next";
import { useCallback, useState } from "react";
import CustomTooltip from "../../../../components/Tooltip/Tooltip";
import fileTypeHelper from "../../../../utils/fileTypeHelper";
import {
  CV,
  DEFAULT_PAGINATION_OPTIONS,
  GET,
  PAGINATION_DEFAULT_LIMIT,
} from "../../../../utils/globalConstants";
import toast from "react-hot-toast";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";

const interviewTypeOptions = [
  {
    id: 1,
    label: i18n.t("projects.competencyInterview"),
  },
  {
    id: 2,
    label: i18n.t("projects.englishTest"),
  },
];

const useHeatmapVM = () => {
  const { projectId } = useParams();
  const [selectedInterviewType, setSelectedInterviewType] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>("");
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const start = searchParams.get("start");
  const limit = searchParams.get("limit");
  const startValue = parseInt(start!) || 0;
  const limitValue = parseInt(limit!) || PAGINATION_DEFAULT_LIMIT;
  const isValidLimit = DEFAULT_PAGINATION_OPTIONS.some(
    (option) => parseInt(option.label) === limitValue
  );
  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({
    start: startValue,
    limit: isValidLimit ? limitValue : PAGINATION_DEFAULT_LIMIT,
  });
  const navigate = useNavigate();

  const { mutateAsync: getSignedUrl } =
    useGraphQLMutation(GetSignedUrlDocument);

  const { data: searchData } = useGraphQLQuery(
    SearchDocument,
    {
      enabled: !!searchString,
    },
    {
      index: "client_candidate",
      query: searchString ?? "",
      limit: pagination.limit,
      offset: pagination.start,
    }
  );

  const clientCandidateIds =
    searchData?.search?.clientCandidate?.map((item) => item?.id) || [];

  const listFilter = {
    _and: [
      { project_id: { _eq: projectId } },
      searchString
        ? {
            client_candidate_id: { _in: clientCandidateIds },
          }
        : {},
    ],
  };

  const { data: scoresData, isLoading } = useGraphQLQuery(
    GetVideoInterviewScoresByProjectIdDocument,
    {},
    {
      interviewType:
        selectedInterviewType === 1
          ? Video_Interview_Type_Enum.CompetencyAssessment
          : Video_Interview_Type_Enum.EnglishAssessment,
      listFilter: listFilter,
      projectId,
      limit: pagination.limit,
      offset: pagination.start,
    }
  );

  const data =
    scoresData?.client_candidate_project
      ?.filter(
        (item) => item.video_interviews && item.video_interviews.length > 0
      )
      .flatMap((item, index) =>
        item.video_interviews.map((interview) => {
          const candidateName = `${interview.client_candidate_project.client_candidate.name} ${interview.client_candidate_project.client_candidate.surname}`;
          const reviewMetrics = interview.video_interview_questions.map(
            (question) => {
              const matchedResponse =
                question.review_metric_question.review_metric_question_responses.find(
                  (response) =>
                    question.video_interview_score.some(
                      (score) =>
                        score.review_metric_question_response_id === response.id
                    )
                );

              return {
                description:
                  question.review_metric_question.review_metric.description,
                score: matchedResponse ? matchedResponse.score : null,
              };
            }
          );

          return {
            id: index,
            interviewId: interview.id,
            candidate: candidateName,
            score: interview.score,
            reviewMetrics: reviewMetrics,
            clientCandidateId:
              interview.client_candidate_project.client_candidate.id,
          };
        })
      ) || [];

  const handleDownload = useCallback((url: string, name: string) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const linkkk = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = linkkk;
        a.download = name;
        a.click();
      });
    });
  }, []);

  const handleDownloadCVButton = async (id: string) => {
    let fileUrlData: any;
    const fileName = scoresData?.client_candidate_project.find(
      (item) => item.client_candidate_id === id
    )?.client_candidate.cv_file_name;
    if (fileName) {
      const type = fileTypeHelper(fileName);
      fileUrlData = await getSignedUrl({
        signedUrlInput: {
          contentType: type.type!,
          filenames: [fileName],
          folder: CV,
          operation: GET,
        },
      });

      handleDownload(
        fileUrlData?.getSignedUrl?.signedUrls[0] as string,
        fileName
      );
    } else {
      toast(
        <ToastAlert description={i18n.t("projects.noCvError")} type="error" />,

        {
          id: "cvDownloadError",
        }
      );
    }
  };

  const headerItemList = [
    i18n.t("projects.order"),
    i18n.t("projects.candidateName"),
    i18n.t("projects.scoreText"),
    "",
    ...(data.length > 0 && data[0].reviewMetrics
      ? data[0].reviewMetrics.map((metric, index) => (
          <NSHeatmapStyle.IconContainer
            key={index}
            data-tooltip-id={`metric-tooltip-${index}`}
            data-tooltip-content={metric.description}
            data-tooltip-place="top"
          >
            #{index + 1}
            <CustomTooltip id={`metric-tooltip-${index}`}>
              {metric.description}
            </CustomTooltip>
          </NSHeatmapStyle.IconContainer>
        ))
      : []),
  ];

  const heatmapTableData: NSTableListType.ITableListItem[] =
    data.length > 0
      ? data.map((item, index) => {
          return {
            id: item.id.toString(),
            informations: [
              {
                id: "1",
                information: (index + 1).toString(),
              },
              {
                id: "2",
                information: item.candidate,
              },
              {
                id: "3",
                information: <HeatmapBadge score={item.score ?? 0} />,
              },
              {
                id: "4",
                information: (
                  <NSHeatmapStyle.StyledIcons>
                    <span
                      data-tooltip-id={`icon-tooltip-download-${item.id}`}
                      data-tooltip-content={i18n.t("projects.downloadCv")}
                      onClick={() => {
                        handleDownloadCVButton(item.clientCandidateId);
                      }}
                    >
                      <Icon name="Download" />
                      <CustomTooltip id={`icon-tooltip-download-${item.id}`}>
                        {i18n.t("projects.downloadCv")}
                      </CustomTooltip>
                    </span>
                    <span
                      data-tooltip-id={`icon-tooltip-star-${item.id}`}
                      data-tooltip-content={i18n.t("projects.candidateReport")}
                      onClick={() => {
                        navigate(
                          `/projects/detail/${projectId}/candidate/candidate-score-result/${
                            item.interviewId
                          }/${
                            selectedInterviewType === 1
                              ? "question"
                              : "interview"
                          }/overview`,
                          {
                            state: {
                              projectId,
                              interviewId: item.interviewId,
                              hasCameFromHeatmap: true,
                            },
                          }
                        );
                      }}
                    >
                      <Icon name="Star" />
                      <CustomTooltip id={`icon-tooltip-star-${item.id}`}>
                        {i18n.t("projects.candidateReport")}
                      </CustomTooltip>
                    </span>
                  </NSHeatmapStyle.StyledIcons>
                ),
              },
              ...item.reviewMetrics.map((metric, metricIndex) => ({
                id: (metricIndex + 5).toString(),
                information: (
                  <HeatmapBadge withPercentage score={metric.score ?? 0} />
                ),
              })),
            ],
          };
        })
      : [];

  const handleSetPagination = useCallback((start: number, limit: number) => {
    setPagination({ start, limit });

    navigate(
      `/projects/detail/${projectId}/heatmap?start=${start}&limit=${limit}`,
      {
        state: { projectId },
      }
    );
  }, []);

  return {
    heatmapTableData,
    headerItemList,
    interviewTypeOptions,
    selectedInterviewType,
    searchString,
    isLoading,
    totalItemCount:
      scoresData?.client_candidate_aggregate.aggregate?.count ?? 0,
    pagination,
    setSearchString,
    setSelectedInterviewType,
    handleSetPagination,
  };
};

export default useHeatmapVM;
